<template>
  <div class="main">
    <div class="main-left">
      <img
        @click="tohomepage"
        src="../../../assets/image/登录页/科研人登录logo.png"
        alt=""
      >
       <span
        :class="{ active: $store.state.navActive == 1 }"
        @click="navClick(1)"
      >
        <router-link :to="{ path: '../../pc/content/homePage' }">首页</router-link>
      </span>
      <span
        :class="{ active: $store.state.navActive == 2 }"
        @click="navClick(2)"
      >
        <router-link :to="{ path: '../../pc/content/reportingGuidelines' }"
          >最新指南</router-link
        >
      </span>
      <span
        :class="{ active: $store.state.navActive == 6 }"
        @click="navClick(6)"
      >
        <router-link :to="{ path: '../../pc/content/mySubscription' }">我的订阅</router-link>
      </span>
      <span
        :class="{ active: $store.state.navActive == 5 }"
        @click="navClick(5)"
      >
        <router-link :to="{ path: '../../pc/content/depthOfTheArticle' }">深度文章</router-link>
      </span>
      <!-- <span
        :class="{ active: $store.state.navActive == 4 }"
        @click="navClick(4)"
      >
        <router-link :to="{ path: 'scientificResearchProject' }"
          >立项数据</router-link
        >
      </span> -->
    </div>
    <div
      class="loginright"
      v-if="$store.state.islogin==='true'"
    >
      <img
        v-if="$store.state.ispro==='false'"
        @click="tovip"
        src="../../../assets/image/升级会员.png"
        alt=""
      >
      <div
        class="main-right successlogin"
        @mouseenter="mouseenterUserImg()"
      >
        <img
          src="../../../assets/image/登录成功.png"
          alt=""
        >
        <div
          class="userList"
          v-show="isshowUserlist"
          @mouseleave="mouseleaveUserImg()"
        >
          <div
            class="userList-item"
            id="loginout"
            @click="loginoouClick()"
          >退出登录</div>
        </div>
      </div>
    </div>
    <div
      class="main-right"
      v-else
    >
      <span @click="tologin">登录</span>
      <span
        class="active"
        @click="toRegistered"
      >免费注册</span>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      navActive: '',
      // 控制用户列表显示变量
      isshowUserlist: false

    }
  },
  methods: {
    // 跳转到首页
    tohomepage () {
      localStorage.setItem('navActive', 1)
      this.$store.commit('setnavActive', 1)
      this.$router.push({
        path: '/pc/content/homePage'
      })
    },
    tologin: function () {
      this.$router.push({
        path: '/pc/loginroot/login'
      })
    },
    // 跳转到充值vip页面
    tovip () {
      this.$router.push(
        {
          path: '/pc/viproot/vip'
        }
      )
    },
    // navClick: function (val) {
    //   this.$data.navActive = val
    // },
      navClick: function (val) {
      this.$data.navActive = val;
      localStorage.setItem("navActive", val);
      this.$store.commit("setnavActive", val);
    },
    // 鼠标移动到用户头像显示用户列表
    mouseenterUserImg () {
      this.$data.isshowUserlist = true
    },
    // 鼠标移出用户头像隐藏用户列表
    mouseleaveUserImg () {
      this.$data.isshowUserlist = false
    },
    // 退出登录点击事件
    loginoouClick () {
      localStorage.setItem('setLoginStatus', false)
      localStorage.removeItem('usermessage')
      this.$store.commit('setLoginStatus', false)
    },
    // 注册
    toRegistered: function () {
      this.$router.push({
        path: '/pc/loginroot/registered'
      })
    }
  }

}
</script>

<style lang="scss" scoped>
.main {
  width: 1200px !important;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
   .main-left {
    display: flex;
    justify-content: left;
    align-items: center;
    img {
      margin: 11px 32px;
      height: 51px;
    }
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 7px 14px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 24px;
      color: #333333;
      margin-right: 18px;
      a {
        color: #333333;
      }
    }
    span.active {
      // background: #f8f8f8;
      // border-radius: 12px;
      position: relative;
      &::before{content: ""; width: 22px;
          height: 3px;
          background-color: #5e068c;
          position: absolute;
          left: 0;
          right: 0;margin: 0 auto;
          bottom: -5px;}
    }
  }
  .main-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    position: relative;
    .userList {
      border: 1px solid #d1d1d1;
      position: absolute;
      width: 120px;
      top: 41px;
      left: -25px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
       // line-height: 24px;
      line-height: 2;
      text-align: center;
      overflow: hidden;
      background-color: #fff;
      opacity: 1;
      cursor: pointer;
      .userList-item {
        padding: 0px 15px;
      }
      .userList-item:hover {
        background-color: #5e068c;
        color: #fff;
      }
    }
    img {
      width: 36px;
      height: 36px !important;
    }
    span {
      cursor: pointer;
      margin-left: 18px;
      padding: 8px 12px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 24px;
      color: #333333;
    }
    span.active {
      background-color: #5e068c;
      border-radius: 4px;
      color: #fff;
    }
  }
  .successlogin {
    display: flex;
    flex-direction: column;
  }
  .loginright {
    display: flex;
    align-items: center;
    img {
      height: 36px;
    }
  }
}
</style>
